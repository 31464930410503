/** @format */

import PropTypes from "prop-types"
import React, { useRef, useImperativeHandle, useState } from "react"
import { Link } from "@reach/router"
import PingSelector from "./PingSelector"
import { CreatePingImage } from "images"
import { locInfoUpd } from "services/pingService"
//import { preloadImages } from "images/pings"
import i18n from "util/i18n"
import { CreatePingButton, LogoText, StyledNavBar, LoginLogoutBtnDiv } from "./styledComponents"

const propTypes = {
  activePings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      category: PropTypes.string
    })
  ),
  userEmail: PropTypes.string,
  loggedIn: PropTypes.bool,
  onLogout: PropTypes.func,
  onPingTypeSelect: PropTypes.func.isRequired,
  hideLinks: PropTypes.bool.isRequired,
  handlelocPerm: PropTypes.func,
    mappo: PropTypes.object,
    appName: PropTypes.string.isRequired
}

const defaultProps = {
  activePings: [],
  onLogout: () => {}
}

const opts = {
  enableHighAccuracy: true,
  timeout: 30000,
  maximumAge: 3000,
};

const NavBar = React.forwardRef((props, ref) => {
    const [showPingSelector, setShowPingSelector] = useState(false)
    
  const selectorRef = useRef()
  useImperativeHandle(ref, () => ({
    closeSelector: () => setShowPingSelector(false)
  }))
  const handleCreatePingClick = e => {
      e.stopPropagation()
      navigator.geolocation.getCurrentPosition(
          position => {
          locInfoUpd(position.coords.latitude, position.coords.longitude)              
          props.mappo.setCenter([position.coords.longitude, position.coords.latitude])

        },
        error => {
          console.error("Error getting user location:", error)
        },
          opts
      )
      
    //close all pingleaf dialogs
    setShowPingSelector(true)
  }
  const infoBtn = (
    <Link to="/about" id="about">
      <span>{i18n.t("Nav.aboutUs")}</span>
    </Link>
  )
  const loginLogoutBtn = (
    <LoginLogoutBtnDiv>
      {props.loggedIn ? (
        <a href="/logout">
          <span>
            {" "}
            {i18n.t("Nav.hello")} {props.userEmail}{" "}
          </span>
        </a>
      ) : (
        <a href="/login">
          <span>{i18n.t("Nav.login")}</span>
        </a>
      )}
      {infoBtn}
    </LoginLogoutBtnDiv>
  )
  const createBtn = (
    <CreatePingButton onClick={handleCreatePingClick}>
      <img src={CreatePingImage} alt={i18n.t("Nav.createNewPing")} />
    </CreatePingButton>
  )
    const handlePingTypeSelect = type => {
      navigator.geolocation.getCurrentPosition(
          position => {
          locInfoUpd(position.coords.latitude, position.coords.longitude)              
          props.mappo.setCenter([position.coords.longitude, position.coords.latitude])

        },
        error => {
          console.error("Error getting user location:", error)
        },
          opts
      )
      setShowPingSelector(false)
    props.onPingTypeSelect(type)
  }
  const handleNavClick = e => {
    if (selectorRef?.current?.contains(e.target)) return
    setShowPingSelector(false)
  }
  return (
    <StyledNavBar hideLinks={props.hideLinks} onClick={handleNavClick}>
      <Link to="/" id="logo">
          <LogoText>{props.appName}</LogoText>
      </Link>
      {createBtn}
      {loginLogoutBtn}

      {showPingSelector && (
        <PingSelector
          ref={selectorRef}
          activePings={props.activePings}
          onPingTypeSelect={handlePingTypeSelect}
        />
      )}
    </StyledNavBar>
  )
})

NavBar.propTypes = propTypes
NavBar.defaultProps = defaultProps
NavBar.displayName = "NavBar"

export default NavBar
